<template>
  <div class="bg-02 workspace-switcher flex flex-col items-center gap-1 p-2" :class="{ hidden: !hasWorkspaceSidebar }">
    <div
      v-for="workspace in workspaces"
      :key="workspace.account.id"
      :active="checkIsActive(workspace)"
      class="cursor-pointer rounded-xl bg-transparent p-1 transition-all duration-500"
      :class="{
        'bg-white': checkIsActive(workspace),
        'hover:bg-00': !checkIsActive(workspace),
      }"
      @click="() => setWorkspace(workspace)"
    >
      <Avatar
        :type="AvatarType.Webshop"
        :entity="{
          name: workspace.account.name,
          image: workspace.account.logo,
        }"
        size="xs"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  const workspaceStore = useWorkspacesStore();
  const { setWorkspace, checkIsActive } = workspaceStore;
  const { hasWorkspaceSidebar } = storeToRefs(useOptionsStore());
  const { workspaces } = storeToRefs(workspaceStore);
</script>
